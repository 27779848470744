<template>
  <b-card-code
    no-body
  >
    <b-table
      class="text-center"
      small
      headVariant="dark"
      responsive="sm"
      :items="dataTabla"
      :fields="emcabezado"
      bordered
      
    >
      <h1>{{ dataTable }}</h1>
      <template #cell(resultado)="data"
        >
        <b-button
        v-if="data.value.length > 0"
        :id="`invoice-row-${data.value}`"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        target="_blank"
        variant="flat-success"
        class="btn-icon rounded-circle"
        :href="data.value"
      >
        <feather-icon icon="CheckCircleIcon" size=20 />
      </b-button>
      <b-button
        v-else
        :id="`invoice-row-${data.value}`"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        target="_blank"
        variant="flat-danger"
        class="btn-icon rounded-circle"
      >
      <feather-icon icon="XCircleIcon" size=20 />
    </b-button>
    <b-tooltip
            v-if="data.value.length > 0"
            :target="`invoice-row-${data.value}`"
            placement="top"
          >
            <p class="mb-0">
              Click para ver resultado 
            </p>
          </b-tooltip>
          <!-- tooltip sin resultado -->
          <b-tooltip
            v-else
            :target="`invoice-row-${data.value}`"
            placement="top"        
          >
            <p class="mb-0">
              No se han cargado resultados
            </p>
          </b-tooltip>
      </template>
      <!-- Optional default data cell scoped slot -->
      <template #cell()="data">
        {{ data.value }}
      </template>
    </b-table>
    <template #code>
      {{ codeResponsive }}
    </template>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {BBadge, BTable, BButton,BTooltip} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { codeResponsive } from './code'

export default {
  components: {BCardCode,BBadge,BButton,BTable,BTooltip
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      emcabezado: [],
      dataTabla: [],
      codeResponsive,
    }
  },
  computed: {

    dataTable() {
      return this.mostrarDataTable()
    },
  },

  methods: {
    mostrarDataTable() {
      this.emcabezado = ['Fecha', {
        key: 'Resultado', label: 'Resultado',
      }]
      this.$store.dispatch('apiFast/descargarDatosPacientes').then(response => {
        // console.log('datos correooooo')
        this.dataTabla = response.data
        // this.validar = response.data
        if (this.validar) this.$router.push({ name: 'historial' })
      })
    },
  },
}
</script>
